import { render, staticRenderFns } from "./RiddleTutorialContainer.vue?vue&type=template&id=067a5386&scoped=true&"
import script from "./RiddleTutorialContainer.vue?vue&type=script&lang=ts&"
export * from "./RiddleTutorialContainer.vue?vue&type=script&lang=ts&"
import style0 from "./RiddleTutorialContainer.vue?vue&type=style&index=0&id=067a5386&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067a5386",
  null
  
)

export default component.exports