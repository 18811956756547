var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isEnd)?_c('v-col',{staticClass:"riddle__answer d-flex flex-column justify-start align-center",class:_vm.started ? 'mt-4' : 'top-margin',attrs:{"cols":"11","sm":"10","md":"8","lg":"6","xl":"6"}},[(_vm.started)?_c('RepeatButton',{attrs:{"isDisabled":_vm.showSolution || _vm.disableButtons},nativeOn:{"click":function($event){return _vm.repeatQuestion.apply(null, arguments)}}}):_vm._e(),(!_vm.started)?_c('StartTestButton',{on:{"start":function($event){_vm.started = true;
      _vm.playIntro();}}}):_vm._e(),(_vm.started)?[_c('RiddleQuestion',{attrs:{"question":_vm.currentQuestion.question}}),_c('v-row',{staticClass:"riddle__options d-flex flex-nowrap justify-start pt-0"},[_c('v-col',{staticClass:"d-flex flex-column align-center justify-start flex-wrap",attrs:{"cols":"6","sm":"6","md":"6","lg":"6","xl":"6"}},_vm._l((_vm.currentQuestion.options),function(option,idx){return _c('RiddleAnswerOption',{key:`option-${idx}`,staticClass:"ma-8",class:{
            chosen: _vm.isChosen(idx),
            disabledButton: _vm.disableButtons,
            solution: _vm.currentQuestion.solution.includes(idx),
            highlight: _vm.currentQuestion.solution.includes(idx) && _vm.showSolution,
            darken:
              !_vm.currentQuestion.solution.includes(idx) && _vm.showWrongAnswers,
            disableMultipleClick: _vm.preventDoubleClick,
          },attrs:{"word":option,"data-id":idx},nativeOn:{"click":function($event){return _vm.addToAnswers(idx)}}})}),1)],1),_c('v-row',{staticClass:"btn-row justify-center align-end"},[(_vm.showArrow)?_c('ArrowButton',{attrs:{"disableButtons":_vm.disableButtons,"preventArrow":_vm.preventArrow},nativeOn:{"click":function($event){return _vm.answerQuestion.apply(null, arguments)}}}):_vm._e()],1)]:_vm._e(),_c('audio',{ref:"currentAudio",attrs:{"src":_vm.audioSrc},on:{"ended":_vm.playSound}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }