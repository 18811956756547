
import RiddleModule from '@/store/modules/RiddleModule';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { RiddleQuestionType } from '@/types';
import { clickSound, getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Client, Form } from '../../../api/types';
import ArrowButton from '../../base/ArrowButton.vue';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import RetryMixin from '../RetryMixin';
import RiddleAnswerOption from './RiddleAnswerOption.vue';
import RiddleQuestion from './RiddleQuestion.vue';

@Component({
  components: {
    RiddleQuestion,
    TestEndEmoji,
    ArrowButton,
    RiddleAnswerOption,
    TutorialEndEmoji,
  },
})
export default class RiddleContainer extends RetryMixin {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  started = false;
  answers: number[] = [];
  start = 0;
  stop = 0;
  disableButtons = true;

  get currentQuestion(): RiddleQuestionType {
    return this.riddleModule.currentQuestion;
  }

  get isEnd(): boolean {
    return (
      this.riddleModule.overallQuestions ===
      this.riddleModule.result.questionsAnswered
    );
  }

  get isTherapyClient(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  mounted(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 6500);
  }

  startInitial(): void {
    this.start = performance.now();
  }

  async updated(): Promise<void> {
    this.start = performance.now();

    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.riddleModule.saveStatus(
          this.userModule.currentUser._id,
        );
        //INFO set finished form
        const userId = this.userModule.currentUser._id;
        const updateData =
          this.riddleModule.chosenForm === Form.a
            ? { doneA: true }
            : { doneB: true };
        await this.userModule.updateUser({
          id: userId,
          data: updateData,
        });

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.riddleModule.finishedAndSavedTest();

          if (this.isTherapyClient) {
            try {
              await this.schoolModule.decreaseValidTests(
                this.schoolModule.slug,
              );
              await this.schoolModule.getBasicSchoolInfo(
                this.schoolModule.schoolId,
              );
            } catch (error) {
              console.log('could not fetch school info', error);
            }
          }
          // INFO after "gratuliere alles geschafft" going to congratulations
          const congratulations = new Audio('/audio/gratuliere_alles.mp3');
          const superAudio = new Audio('/audio/tutorials/u1/HELP3833.mp3');

          congratulations.addEventListener(
            'ended',
            () => {
              setTimeout(async () => {
                this.riddleModule.haveShownCongrats(this.$route.params.form);
                this.$router.replace({
                  name: 'congratulations',
                  params: { schoolName: getSchoolPrefix() },
                });
                setTimeout(() => superAudio.play(), 1000);
              }, 2300);
            },
            { once: true },
          );
          congratulations.play();
        }
      } catch (error: any) {
        this.riddleModule.resetDone();
        console.error(
          '[RIDDLE] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  addToAnswers(answerIdx: number): void {
    clickSound.play();
    if (this.answers.includes(answerIdx)) {
      const idx = this.answers.indexOf(answerIdx);
      this.answers.splice(idx, 1);
    } else {
      this.answers.push(answerIdx);
    }
    this.answers.sort();
  }

  isChosen(idx: number): boolean {
    return this.answers.includes(idx);
  }

  answerQuestion(): void {
    //if nothing selected don't check
    if (this.answers.length === 0) {
      return;
    }
    this.stop = performance.now();
    const time = this.stop - this.start;

    //CHECK IF CORRECTLY ANSWERED
    const isCorrect = this.checkAnswer();
    const answers: string[] = this.answers.map(
      (idx) => this.currentQuestion.options[idx],
    );

    this.riddleModule.addAnswer({
      question: this.currentQuestion.question,
      answers: answers,
      time: Math.round(time),
      isCorrect: isCorrect,
    });
    this.answers = [];
  }

  checkAnswer(): boolean {
    const solution = this.currentQuestion.solution;
    return (
      solution.length === this.answers.length &&
      solution.every((answer, idx) => answer === this.answers[idx])
    );
  }
}
