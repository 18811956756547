
import Vue from 'vue';
import Component from 'vue-class-component';
@Component
export default class CongratulationsContainer extends Vue {
  hasImage = false;

  setHasImage(): void {
    this.hasImage = true;
  }

  get leo(): string {
    return require('@/assets/images/lion.png');
  }
}
