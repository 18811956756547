
import RiddleModule from '@/store/modules/RiddleModule';
import UserModule from '@/store/modules/UserModule';
import { RiddleQuestionType } from '@/types';
import { clickSound } from '@/utils/common';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import ArrowButton from '../../base/ArrowButton.vue';
import RepeatButton from '../../base/RepeatButton.vue';
import StartTestButton from '../../base/StartTestButton.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import RiddleAnswerOption from './RiddleAnswerOption.vue';
import RiddleQuestion from './RiddleQuestion.vue';
import TutorialMixin from '../TutorialMixin';
import { Ref } from 'vue-property-decorator';

@Component({
  components: {
    RiddleQuestion,
    TutorialEndEmoji,
    ArrowButton,
    RiddleAnswerOption,
    RepeatButton,
    StartTestButton,
  },
})
export default class RiddleTutorialContainer extends TutorialMixin {
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  idx = 0;
  answered = 0;
  answers: number[] = [];
  totalQuestions = this.riddleModule.tutorialData.length;
  disableButtons = true;
  preventDoubleClick = false;
  preventArrow = false;
  showSolution = false;
  showWrongAnswers = false;
  showArrow = true;
  started = false;
  canPlayTimeout = 0;
  tutorialEndSound?: HTMLAudioElement;
  clickCorrectWords?: HTMLAudioElement;

  @Ref() currentAudio!: HTMLAudioElement;
  audioSrc = '/audio/click.mp3';

  get audios() {
    return {
      readQuestion: '/audio/tutorials/u5/HELP248.mp3',
      clickCorrectWords: '/audio/tutorials/u5/HELP286.mp3',
      showCorrectWords: '/audio/tutorials/u5/HELP276.mp3',
      clickArrow: '/audio/tutorials/u5/HELP3818.mp3',
      congrats: '/audio/tutorials/u5/HELP3830.mp3',
      super: '/audio/tutorials/u5/HELP3833.mp3',
      wrong: '/audio/tutorials/u5/HELP239.mp3',
      readyGo: '/audio/tutorials/u5/HELP3836.mp3',
      default: '/audio/click.mp3',
    };
  }

  get currentQuestion(): RiddleQuestionType {
    return this.riddleModule.tutorialQuestions[this.idx];
  }

  get currentSolution(): number[] {
    return this.riddleModule.tutorialQuestions[this.idx].solution;
  }

  get isEnd(): boolean {
    return this.answered === this.totalQuestions;
  }

  addEventListeners(): void {
    this.currentAudio?.addEventListener('canplay', this.onCanPlay);
    this.currentAudio?.addEventListener(
      'canplaythrough',
      this.onCanPlayThrough,
    );
  }

  onCanPlay() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout = window.setTimeout(
      this.onCanPlayThrough.bind(this),
      6 * 1000,
    );
  }

  onCanPlayThrough() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout && (this.canPlayTimeout = 0);
    this.currentAudio?.removeEventListener('canplay', this.onCanPlay);
    this.currentAudio?.removeEventListener(
      'canplaythrough',
      this.onCanPlayThrough,
    );
    this.userModule.setActiveAudio(this.currentAudio);

    if (this.audioSrc === this.audios.super) {
      setTimeout(() => {
        this.currentAudio?.play();
      }, 1500);
    } else if (this.audioSrc === this.audios.clickCorrectWords) {
      setTimeout(() => {
        this.currentAudio?.play();
      }, 1000);
    } else if (this.audioSrc === this.audios.showCorrectWords) {
      setTimeout(() => {
        this.currentAudio?.play();
      }, 1000);
    } else {
      this.currentAudio?.play();
    }
  }

  playSound() {
    switch (this.audioSrc) {
      case this.audios.default:
        this.audioSrc = this.audios.readQuestion;
        this.addEventListeners();
        break;
      case this.audios.readQuestion:
        this.disableButtons = false;
        this.preventArrow = false;
        // INFO to enable repeat question on first question change audio here
        // this should not play!
        this.audioSrc = this.audios.readyGo;
        break;
      case this.audios.super:
        setTimeout(() => {
          this.idx++;
          this.answered++;
          if (this.isEnd) {
            if (this.isTutorialOnlyVersion) {
              this.redirectOnTutorialOnly();
            } else {
              this.riddleModule.finishedTutorial();
              setTimeout(() => {
                this.userModule.setActiveAudio(this.tutorialEndSound!);
                this.tutorialEndSound!.play();
              }, 1000);
            }
          } else {
            this.playNextQuestion();
          }
        }, 1500);
        break;
      case this.audios.clickCorrectWords:
        this.disableButtons = false;
        this.preventArrow = false;
        break;
      case this.audios.wrong:
        // highlight correct letter
        this.answers = this.currentSolution;
        this.showSolution = true;
        //darken wrong answers
        this.showWrongAnswers = true;
        this.showArrow = false;

        this.audioSrc = this.audios.showCorrectWords;
        this.addEventListeners();
        break;
      case this.audios.showCorrectWords:
        //enable arrow after sound is finished
        this.preventArrow = false;
        this.disableButtons = false;
        // play next question
        setTimeout(() => {
          this.idx++;
          this.answered++;
          //play finished tutorial sound
          if (this.isEnd) {
            if (this.isTutorialOnlyVersion) {
              this.redirectOnTutorialOnly();
            } else {
              this.userModule.setActiveAudio(this.tutorialEndSound!);
              this.riddleModule.finishedTutorial();
              setTimeout(() => {
                this.tutorialEndSound!.play();
              }, 1000);
            }
          } else {
            this.playNextQuestion();
          }
        }, 2500);
        break;
      default:
        break;
    }
  }

  playNextQuestion(): void {
    if (this.idx === 0) {
      this.playIntro();
    } else if (!this.isEnd) {
      this.answers = [];
      this.preventDoubleClick = false;
      this.disableButtons = true;
      this.showSolution = false;
      this.showWrongAnswers = false;
      this.showArrow = true;

      this.audioSrc = this.audios.clickCorrectWords;
      this.addEventListeners();
    }
  }

  repeatQuestion(): void {
    this.userModule.stopActiveAudio();
    this.answers = [];
    this.preventDoubleClick = false;
    this.disableButtons = true;
    this.showSolution = false;
    this.showWrongAnswers = false;
    this.showArrow = true;
    this.idx = 0;
    this.answered = 0;
    this.audioSrc = this.audios.default;
    this.playIntro();
  }

  playIntro(): void {
    if (!this.tutorialEndSound) {
      this.tutorialEndSound = new Audio(this.audios.readyGo);
    }
    this.playSound();
  }

  playCorrectAnswer(): void {
    this.audioSrc = this.audios.super;
    this.addEventListeners();
  }

  playWrongAnswer(): void {
    this.audioSrc = this.audios.wrong;
    this.addEventListeners();
  }

  addToAnswers(answerIdx: number): void {
    clickSound.play();
    if (this.answers.includes(answerIdx)) {
      const idx = this.answers.indexOf(answerIdx);
      this.answers.splice(idx, 1);
    } else {
      this.answers.push(answerIdx);
    }
    this.answers.sort();
  }

  isChosen(idx: number): boolean {
    return this.answers.includes(idx);
  }

  answerQuestion(): void {
    //if nothing selected don't check
    if (this.answers.length === 0) {
      return;
    }
    this.preventDoubleClick = true;
    this.preventArrow = true;
    this.disableButtons = true;
    //CHECK IF CORRECTLY ANSWERED
    const isCorrect = this.checkAnswer();
    if (isCorrect) {
      this.playCorrectAnswer();
    } else {
      this.playWrongAnswer();
    }
  }

  checkAnswer(): boolean {
    const solution = this.currentQuestion.solution;
    return (
      solution.length === this.answers.length &&
      solution.every((answer, idx) => answer === this.answers[idx])
    );
  }

  beforeDestroy() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
  }
}
