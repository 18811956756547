
import RiddleModule from '@/store/modules/RiddleModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import TestOptionButton from './TestOptionButton.vue';
import { TestOptionItem } from '../../../utils/helpers';

@Component({ components: { TestOptionButton } })
export default class TestOptionContainer extends Vue {
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  testOptions: TestOptionItem[] = [
    {
      id: 1,
      title: 'Buchstabenjagd',
      img: '@/assets/images/flower.png',
    },
    {
      id: 2,
      title: 'Fantasiewörter',
      img: '@/assets/images/flower.png',
    },
    {
      id: 3,
      title: 'Silbensuche',
      img: '@/assets/images/flower.png',
    },
    {
      id: 4,
      title: 'Wörter finden',
      img: '@/assets/images/flower.png',
    },
    {
      id: 5,
      title: 'Wörterrätsel',
      img: '@/assets/images/flower.png',
    },
  ];
}
